<template>

        <ul class="tree-list">
            <node-tree :child-prop="childProp" :label-prop="labelProp" :node-item-data="treeData"></node-tree>
        </ul>

</template>

<script>
    import NodeTree from "./nodeTree";

    export default {
        props: {
            treeData: Object,
            childProp: String,
            labelProp: String,
        },
        components: {
            NodeTree
        }
    };
</script>

<style>
    .tree-list{
        list-style: none;
    }
    .tree-list ul {
        padding-left: 16px;
        margin: 6px 0;
        list-style: none;
    }
    .tree-list li {
        margin-bottom: 7px;
    }
</style>